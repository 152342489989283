import Typography from 'typography'

const typography = new Typography({
    bodyColor: 'white',
    headerColor: 'white',
    googleFonts: [
        {
            name: 'Playfair Display',
            styles: [
                '900',
            ],
        },
        {
            name: 'Lato',
            styles: [
                '400',
                '700',
            ],
        },
    ],
    headerFontFamily: ['Playfair Display', 'serif'],
    bodyFontFamily: ['Lato', 'sans-serif'],
    bodyWeight : 'normal',
    includeNormalize: true
})

export default typography;